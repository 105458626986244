import React from "react"
import "./style.scss"

class ContactForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      submitted: false,
      errored: false,
    }
  }

  submitHandler = ev => {
    ev.preventDefault()
    alert("yo")
  }

  render() {
    return (
      <div id="Contact" className="contact-form-wrap">
        <div className="contact-form-heading-wrap">
          <h2 className="contact-heading">Contact us</h2>
        </div>
        <div className="contact-form w-form">
          <form
            data-name="Get In Touch Form"
            name="wf-form-Get-In-Touch-Form"
            method="get"
            className="get-in-touch-form"
            onSubmit={this.submitHandler.bind(this)}
          >
            <label htmlFor="name">Name</label>
            <input
              type="text"
              className="text-field cc-contact-field w-input"
              maxLength="256"
              name="Name"
              data-name="Name"
              placeholder="Enter your name"
              id="Name"
            />
            <label htmlFor="Email">Email Address</label>
            <input
              type="email"
              className="text-field cc-contact-field w-input"
              maxLength="256"
              name="Email"
              data-name="Email"
              placeholder="Enter your email"
              id="Email"
              required=""
            />
            <label htmlFor="Message">Message</label>
            <textarea
              id="Message"
              name="Message"
              placeholder="Hi there, I’m reaching out because I think we can collaborate…"
              maxLength="5000"
              data-name="Message"
              className="text-field cc-textarea cc-contact-field w-input"
            ></textarea>
            <input
              id="submit-get-in-touch"
              type="submit"
              value="Submit"
              data-wait="Please wait..."
              className="button w-button"
            />
          </form>
          {this.state.submitted && (
            <div className="status-message cc-success-message w-form-done">
              <div>Thank you! Your submission has been received!</div>
            </div>
          )}{" "}
          {this.state.errored && (
            <div className="status-message cc-error-message w-form-fail">
              <div>Oops! Something went wrong while submitting the form.</div>
            </div>
          )}
        </div>
      </div>
    )
  }
}

export default ContactForm
