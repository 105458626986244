import React from "react"

import Layout from "@components/layout"
// import Seo from "@components/seo"
import ContactForm from "@components/_molecules/form_contact"
import Seo from "@components/SEO"

const IndexPage = () => (
  <Layout>
    <Seo title="Home" />
    <main>
      <div className="section cc-store-home-wrap">
        <div className="container">
          <div className="motto-wrap">
            <div className="heading-jumbo-small">
              Streamline your business, eliminate manual process, and put your
              customers first.
              <br />
            </div>
          </div>
          <div className="divider"></div>
          <div className="home-content-wrap">
            <div className="w-layout-grid about-grid">
              <div id="w-node-76c147234d34-e77119dd">
                <div className="home-section-wrap">
                  <div id="About" className="anchor"></div>
                  <div className="label cc-light">About</div>
                  <h2 className="section-heading">Who we are</h2>
                  <p className="paragraph-light">
                    Bay Park Software is a group of software professionals with
                    a wide range of experience working at large, fortune 500
                    companies, to local non-profits. We provide decades of
                    experience with software development, project management,
                    and web development. Based in San Diego, CA.
                  </p>
                </div>
              </div>
              <img
                src="/images/homepage/southsandiego-lightgrey.png"
                sizes="(max-width: 479px) 94vw, (max-width: 767px) 96vw, (max-width: 991px) 94vw, 919.0000610351562px"
                id="w-node-76c147234d3f-e77119dd"
                alt=""
              />
            </div>
            <div className="w-layout-grid about-grid cc-about-2">
              <div id="w-node-76c147234d41-e77119dd">
                <div className="home-section-wrap">
                  <div id="Work" className="anchor"></div>
                  <div className="label cc-light">work</div>
                  <h2 className="section-heading">What we do</h2>
                  <p className="paragraph-light">
                    At Bay Park Software, we specialize in optimizing your
                    business through software automation. Beyond just developing
                    your software, we work closely with you, co-engineering
                    solutions tailored to your specifications.{" "}
                  </p>
                </div>
              </div>
              <img
                src="/images/homepage/sdbay-warm.jpg"
                sizes="(max-width: 479px) 94vw, (max-width: 767px) 96vw, 94vw"
                id="w-node-76c147234d4c-e77119dd"
                alt=""
              />
            </div>
          </div>
          <ContactForm />
        </div>
      </div>
    </main>
  </Layout>
)

export default IndexPage
